import '../sass/pages/conditioning.scoped.scss';
import React from 'react';
import Seo from '../components/Seo';
import Layout from '../components/blocks/Layout';
import { StaticImage } from "gatsby-plugin-image"
import { ModalProvider } from '../context/ModalContext';

const ConditioningWrapper = () => {
  return (
    <ModalProvider>
      <Conditioning />
    </ModalProvider>
  );
};

const Conditioning = () => {
  return (
    <Layout>
      <Seo title='Кондиционирование' />
      <h1>Кондиционирование</h1>
      <div className="content">
        <p className='lead'>
        <span>ТОО «Invest Group Company» занимается <strong>продажей</strong> большого
        количества <strong>кондиционерного и тепло-охлаждающего оборудования</strong>, и их <strong>монтаж</strong>.</span>
          <span>В наличии оборудование всех известных брендов по <strong>ценам
        производителя</strong>.</span>
        </p>
        <div className="categories">
          <div className="category">
            <div className="category-img">
              <StaticImage
                placeholder='none'
                src='../images/conds/chillers.png'
                alt='Чиллер'
              />
            </div>
            <div className="category-info">
              <h2 className='category-name'>Чиллеры</h2>
              <ul className='text-list'>
                <li>Чиллеры с воздушным охлаждением, с осевыми вентиляторами, наружной установки</li>
                <li>Чиллеры с воздушным охлаждением конденсатора, с центробежными вентиляторами внутренней установки</li>
                <li>Чиллеры с водяным охлаждением конденсатора</li>
                <li>Чиллеры с выносным конденсатором </li>
              </ul>
            </div>
          </div>
          <div className="category">
            <div className="category-img">
              <StaticImage
                placeholder='none'
                src='../images/conds/fancoils.png'
                alt='Фанкойл'
              />
            </div>
            <div className="category-info">
              <h2 className='category-name'>Фанкойлы</h2>
              <ul className='text-list'>
                <li>Фанкойлы настенные и колонные</li>
                <li>Фанкойлы мобильные и кассетные</li>
                <li>Фанкойлы канальные и прецизионные</li>
                <li>Крышные фанкойлы</li>
                <li>Лифтовые фанкойлы</li>
                <li>Напольно-потолочные фанкойлы</li>
              </ul>
            </div>
          </div>
          <div className="category">
            <div className="category-img">
              <StaticImage
                placeholder='none'
                src='../images/conds/compressor-and-condenser-units.png'
                alt='Компрессорно-конденсаторный блок'
              />
            </div>
            <div className="category-info">
              <h2 className='category-name'>Компрессорно-конденсаторные блоки</h2>
              <ul className='text-list'>
                <li>Воздушного охлаждения с центробежными вентиляторами</li>
                <li>Воздушного охлаждения с осевыми вентиляторами</li>
                <li>Водяного охлаждения</li>
                <li>С выносным конденсатором</li>
              </ul>
            </div>
          </div>
          <div className="category">
            <div className="category-img">
              <StaticImage
                placeholder='none'
                src='../images/conds/air-heat-curtain.png'
                alt='Воздушно-тепловая завеса'
              />
            </div>
            <div className="category-info">
              <h2 className='category-name'>Воздушно-тепловые завесы</h2>
              <ul className='text-list'>
                <li>Тепловые завесы с электрическим обогревом</li>
                <li>С водяным обогревом и без обогрева</li>
                <li>Воздушная завеса</li>
                <li>Воздушно-тепловая завеса</li>
              </ul>
            </div>
          </div>
          <div className="category">
            <div className="category-img">
              <StaticImage
                placeholder='none'
                src='../images/conds/air-conditioners.png'
                alt='Кондиционер'
              />
            </div>
            <div className="category-info">
              <h2 className='category-name'>Кондиционеры</h2>
              <ul className='text-list'>
                <li>Прецизионные кондиционеры</li>
                <li>Мультизональные системы</li>
                <li>Инверторные</li>
              </ul>
            </div>
          </div>
          <div className="category">
            <div className="category-img">
              <StaticImage
                placeholder='none'
                src='../images/conds/split-systems.png'
                alt='Сплит-система'
              />
            </div>
            <div className="category-info">
              <h2 className='category-name'>Сплит-системы</h2>
              <ul className='text-list'>
                <li>Полупромышленные инверторные сплит-системы</li>
                <li>Полупромышленные сплит-системы с низкотемпературным комплектом</li>
                <li>Полупромышленные сплит-системы постоянной производительности</li>
                <li>Настенные сплит-системы</li>
                <li>Мультисплит-системы</li>
              </ul>
            </div>
          </div>
          <div className="category">
            <div className="category-img">
              <StaticImage
                placeholder='none'
                src='../images/conds/dehumidifiers.png'
                alt='Осушитель'
              />
            </div>
            <div className="category-info">
              <h2 className='category-name'>Осушители</h2>
              <ul className='text-list'>
                <li>Стандартные</li>
                <li>Систем радиационного охлаждения</li>
                <li>Бассейнов</li>
                <li>Холодильных камер</li>
              </ul>
            </div>
          </div>
        </div>
        <section className='text-section'>
          <h2 className='section-heading'>
            Этапы выполнения монтажа вентиляционных систем
          </h2>
          <p>
            Первым этапом любого проекта является бесплатный выезд специалиста на объект и знакомство с клиентом. Далее происходит сбор исходной информации, натурные измерения и определение пожеланий клиента, на основании которых составляется коммерческое предложение и техническое задание.
          </p>
          <p>
            После согласования технического задания специалисты ТОО «Invest Group Company» приступают к разработке проектной документации системы вентиляции, которая выполняется согласно действующим строительным номам Республики Казахстан.
          </p>
          <p>
            Последним этапом является непосредственно монтаж системы вентиляции, который условно можно разбить на несколько подпунктов:
          </p>
          <div className='list'>
            <ul>
              <li>Подготовительный. Поставка оборудования, воздуховодов, расходных элементов.</li>
              <li>Монтажный. Прокладка вентиляции, сборка воздуховодов и всех элементов конструкции.</li>
              <li>Проверочный. Выполнение пуско-наладочных работ.</li>
              <li>Финальный. Сдача готовой системы в эксплуатацию.</li>
            </ul>
          </div>
        </section>
      </div>
    </Layout>
  )
};

export default ConditioningWrapper;
